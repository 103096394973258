import { combineReducers } from "redux";
import session from "./session";
import cached from "./cached";
import page from "./page";
import profile from "./profile";
import user from "./user";
import site from "./site";
import groups from "./groups";
import organisation from "./organisation";
import unit from "./unit";
import slot from "./slot";
import schedules from "./schedules";
import events from "./events";
import status from "./status";
import option from "./option";
import notes from "./notes";
import name from "./name";
import operation from "./operation";
import upgrade from "./upgrade";
import notifications from "./notifications";
import network from "./network";
import dashboard from "./dashboard";
import tariff from "./tariff";
import weatherSite from "./weatherSite";

const rootReducer = combineReducers({
  session,
  cached,
  page,
  profile,
  user,
  site,
  groups,
  organisation,
  unit,
  slot,
  schedules,
  events,
  status,
  option,
  notes,
  name,
  operation,
  upgrade,
  notifications,
  network,
  dashboard,
  tariff,
  weatherSite,
});

export default rootReducer;
