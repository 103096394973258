import { isEmpty, isNil } from "lodash";

export const GRID_PHASE_OPTIONS = [
  { value: 1, label: "Single Phase" },
  { value: 3, label: "3-Phase" },
];

export const getLabel = (options, value) => {
  const item = options.find((it) => it.value === value);
  return item?.label || "";
};

export const getStateName = (siteDetails, location) => {
  if (siteDetails?.id && location.length > 0) {
    const item = location.find((it) => it.location === siteDetails?.location);
    return item?.state_name;
  }
};

export const getSiteAddressSummary = (siteDetails) => {
  return `${siteDetails?.street}, ${siteDetails?.city}, ${siteDetails?.state}, ${siteDetails?.postcode}`;
};

export const getDnspandPhaseSummary = (dnspOptions, siteDetails) => {
  return `${getLabel(dnspOptions, siteDetails?.dnsp)}, ${getLabel(
    GRID_PHASE_OPTIONS,
    siteDetails?.phases
  )}`;
};

export const getBackupConnectionSummary = (unitDetails) => {
  return unitDetails?.backup
    ? `Enabled, ${unitDetails?.setting?.reserved ?? 0}% reserved capacity`
    : "Disabled";
};

export const getRegionSummary = (region, siteDetails) => {
  return getLabel(region, siteDetails?.region);
};

export const getExportLimitSummary = (unitDetails) => {
  return !isNil(unitDetails?.as4777?.export_limit)
    ? `Enabled, ${unitDetails?.as4777?.export_limit * 10}W`
    : "Disabled";
};

export const powerRateLimitSummary = (unitDetails) =>
  unitDetails?.as4777?.wgra ? `${unitDetails?.as4777?.wgra}%/min` : "NA";

export const getRemoteControlSummary = (unitDetails) => {
  return unitDetails?.drm0
    ? "DRM0 connected / enabled"
    : "DRM0 not connected / disabled";
};

export const getVoltWattSummary = (unitDetails) => {
  return `Volt-Watt ${unitDetails?.as4777?.voltwatt ? "enabled" : "disabled"}`;
};

export const getVoltVarSummary = (unitDetails) => {
  return `Volt-Var ${unitDetails?.as4777?.voltvar ? "enabled" : "disabled"}`;
};

export const getPowerFactorSummary = (unitDetails) => {
  return `Power Factor ${unitDetails?.as4777?.power_factor || ""} 
    (${unitDetails?.as4777?.power_factor > 0 ? "Supplying" : "Absorbing"})`;
};

export const getPvSummary = (unitDetails) => {
  if (!unitDetails?.pv) return "-";
  const pv = Object.keys(unitDetails?.pv).filter(
    (it) => !isNil(unitDetails?.pv[it])
  );
  let external = pv.includes("pvext") ? "+ External PV" : "";
  let internalpv = pv.filter((p) => p !== "pvext").length;
  let internal = `Internal PV - ${internalpv} Channel${
    internalpv !== 1 ? "s" : ""
  }`;

  return `${internal} ${external}`;
};

export const joinBy = (list = [], key = "name", separator = ", ") => {
  const value = list.map((it) => it[key]).join(separator);

  return <span style={{ marginRight: "4px" }}>{value ? value : "-"}</span>;
};

const METER_BASE = [
  { label: "Current", key: "current" },
  { label: "Voltage", key: "voltage" },
  { label: "Power Factor", key: "power_factor" },
  { label: "Active Power", key: "power_active" },
  { label: "Reactive Power", key: "power_reactive" },
  { label: "Apparent Power", key: "power_apparent" },
  { label: "Frequency", key: "frequency" },
];

const getMeterData = (property, matchingKeys, data, isTotal = false) => {
  const key = matchingKeys.find((it) =>
    isTotal ? it === property : it.includes(property)
  );
  if (key) {
    if (data[key]) {
      return data[key];
    }
  } else {
    return "";
  }
};

export const constructMeterBucket = (meterData) => {
  const meterList = [];
  Object.keys(meterData).forEach((key) => {
    if (key === "expiry") return;
    const keys = Object.keys(meterData[key]);
    const list = [];
    METER_BASE.forEach((item, i) => {
      const matchingKeys = keys.filter((it) => it.includes(item.key));
      const row = {
        id: i,
        label: item.label,
        phase1: getMeterData("phase1", matchingKeys, meterData[key]),
        phase2: getMeterData("phase2", matchingKeys, meterData[key]),
        phase3: getMeterData("phase3", matchingKeys, meterData[key]),
        total: getMeterData(item.key, matchingKeys, meterData[key], true),
      };

      list.push(row);
    });

    meterList.push({
      label: key,
      list: list,
    });
  });

  return meterList;
};

export const constructDebugBucket = (debug) => {
  let buckets = {};
  Object.keys(debug).forEach((node) => {
    if (!["events", "fpga", "meter", "bms", "network"].includes(node)) return;

    if (node === "meter") {
      const meterData = constructMeterBucket(debug[node]);
      buckets[node] = meterData;
      return;
    }

    let details = {
      title: "Details",
      value: null,
      nodes: [],
    };
    Object.keys(debug[node]).forEach((key) => {
      let bucket = {
        title: "",
        value: null,
        nodes: [],
      };
      const secondLevel = debug[node][key];

      if (Array.isArray(secondLevel) || secondLevel === null) {
        //
      } else if (typeof secondLevel === "object") {
        bucket.title = key;

        Object.keys(secondLevel).forEach((it) => {
          let child = {
            title: "",
            childrens: [],
          };
          const thirdLevel = secondLevel[it];
          if (typeof thirdLevel === "object") {
            child.title = it;

            Object.keys(thirdLevel).forEach((item) => {
              child.childrens.push({
                label: item,
                value: thirdLevel[item],
              });
            });
          } else if (
            ["string", "boolean", "number"].includes(typeof thirdLevel)
          ) {
            child.childrens.push({
              label: it,
              value: thirdLevel,
            });
          }
          bucket.nodes.push(child);
        });
      } else if (["string", "boolean", "number"].includes(typeof secondLevel)) {
        const sChild = {
          title: "",
          childrens: [
            {
              label: key,
              value: secondLevel,
            },
          ],
        };
        details.nodes.push(sChild);
      }

      if (!buckets[node]) {
        buckets = {
          ...buckets,
          [node]: [],
        };
      }

      if (
        !(
          isEmpty(bucket.title) &&
          isNil(bucket.value) &&
          bucket.nodes.length === 0
        )
      ) {
        buckets[node].push(bucket);
      }
    });
    buckets[node].push(details);
  });

  return buckets;
};
