import { Box, Grid, Skeleton } from "@mui/material";
import { Card } from "empower-energy-owner-components";
import { useEffect, useState } from "react";

const CardLoading = ({ count = 4 }) => {
  const [md, setMd] = useState(12);
  const [list, setList] = useState([]);

  useEffect(() => {
    setList(Array.from({ length: count }));
    setMd(12 / count);
  }, [count]);

  return (
    <Box>
      <Grid container spacing={2}>
        {list &&
          list.map((it, i) => (
            <Grid key={i} item xs={12} md={md}>
              <Card>
                <Box
                  p={2}
                  width="100%"
                  className="flex space-between items-center"
                >
                  <Box className="flex flex-col items-center">
                    <Skeleton
                      animation="wave"
                      variant="circular"
                      width={40}
                      height={40}
                    />
                    <Skeleton
                      animation="wave"
                      variant="text"
                      sx={{ fontSize: "1.5rem", width: "60px" }}
                    />
                  </Box>
                  <Box>
                    <Skeleton
                      animation="wave"
                      variant="text"
                      width={100}
                      sx={{ mb: 1.5 }}
                    />
                    <Skeleton animation="wave" variant="text" width={100} />
                  </Box>
                </Box>
              </Card>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default CardLoading;
