import dayjs from "dayjs";
import { orderBy } from "lodash";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(advancedFormat);

export const YAXIS_WIDTH = "60px";

export const LOWEST_PRICE_COLORS_LIST = [
  "#28B178",
  "#F9B426",
  "#FF6E04",
  "#E11818",
  "#004057",
  "#00B2FF"
];

function hourToIntervalIndex(hour) {
  return Math.floor(hour * 2);
}

export const getTariffColorsByPrices = (prices) => {
  const colors = {};
  if (!prices) return colors;
  orderBy(prices, ["buy"], "asc").forEach((it, i) => {
    colors[it.time_period] = LOWEST_PRICE_COLORS_LIST[i];
  });
  return colors;
};

export const getTimePeriods = (item) => {
  const bucketlength = 48;
  const interval = 0.5;
  const bucket = Array.from({ length: bucketlength }, () => null);

  orderBy(item.time_periods, ["priority"]).forEach((period) => {
    period.time_ranges.forEach((range) => {
      const startIdx = hourToIntervalIndex(range.start_hour);
      const endIdx = hourToIntervalIndex(range.end_hour);

      for (let i = startIdx; i < endIdx; i++) {
        bucket[i] = {
          name: period.name,
          start_hour: range.start_hour,
          end_hour: range.end_hour,
        };
      }
    });
  });

  let count = 0;
  let lastItem = null;
  const list = [];

  bucket.forEach((bucketItem, i) => {
    if (!bucketItem) return;

    if (!lastItem || bucketItem.name === lastItem.name) {
      count += interval;
      lastItem = bucketItem;
    } else {
      list.push({
        ...lastItem,
        count: count,
      });
      count = interval;
      lastItem = bucketItem;
    }

    if (i === bucketlength - 1) {
      list.push({
        ...bucketItem,
        count: count,
      });
    }
  });

  return list;
};

export const getHourTime = (hour) => {
  return dayjs(new Date(2022, 0, 1))
    .set("hour", hour)
    .set("minute", hour % 1 === 0.5 ? "30" : 0)
    .format("h:mma");
};

export const getChartTitle = (season) => {
  let title = season.name;
  const tokens = [];
  orderBy(season.date_ranges, ["start_month"]).forEach((dt) => {
    const start = dayjs(new Date(2022, 0, 1))
      .set("date", dt.start_day)
      .set("month", dt.start_month - 1)
      .format("Do MMM");

    const end = dayjs(new Date(2022, 0, 1))
      .set("date", dt.end_day)
      .set("month", dt.end_month - 1)
      .format("Do MMM");

    tokens.push(`${start} to ${end}`);
  });
  title = `${title} - ${tokens.join(", ")}`;

  return title;
};
