import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { getSiteList } from "actions/site";
import { assignSiteUser, getUserAssignedSite } from "actions/user";
import { renderDeleteActions } from "components/DataTable/renderComponents";
import Modal from "components/Modal";
import DeleteConfirmationDialog from "components/Modal/DeleteConfirmationDialog";
import { SiteName, UnitSerial } from "components";
import { DataTable, FormField } from "empower-energy-owner-components";
import { isEmpty, isNil } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const AssignSiteUserModal = ({ selectedUser, open, onClose }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    site: "",
  });
  const [deleteAssignment, setDeleteAssignment] = useState(null);

  const [filteredSiteList, setFilteredSiteList] = useState([]);

  const loading = useSelector((state) => state.user?.loading);
  const assignedSites = useSelector((state) => state.user?.assignedSites);
  const siteList = useSelector((state) => state.site?.siteOptions);

  useEffect(() => {
    dispatch(getSiteList());
  }, [dispatch]);

  useEffect(() => {
    const list = siteList.filter(
      (it) => !assignedSites.map((as) => as.id).includes(it.id)
    );
    setFilteredSiteList(list);
  }, [assignedSites]);

  useEffect(() => {
    if (!isEmpty(selectedUser)) {
      dispatch(getUserAssignedSite(selectedUser.id));
    }
  }, [selectedUser]);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onClickSave = async () => {
    const data = {
      add: [formData.site],
    };

    await dispatch(assignSiteUser(selectedUser.id, data));
    setFormData({ ...formData, site: "" });
  };

  const onClickDelete = async () => {
    const data = {
      remove: [deleteAssignment.id],
    };

    await dispatch(assignSiteUser(selectedUser.id, data));
    setDeleteAssignment(null);
  };

  const columns = [
    {
      field: "nmi",
      headerName: "Name",
      minWidth: 300,
      flex: 1,
      renderCell: (params) => <SiteName id={params.row.id} />,
    },
    {
      field: "units",
      headerName: "Units",
      width: 240,
      renderCell: (params) => {
        return params.row?.units?.map((unit, i) => (
          <UnitSerial key={i} id={unit.id} />
        ));
      },
    },
    {
      field: "id",
      width: 100,
      headerAlign: "right",
      align: "right",
      headerName: "Actions",
      renderCell: (params) =>
        renderDeleteActions(params, (e, item) => setDeleteAssignment(item)),
    },
  ];

  return (
    <Box>
      <Modal
        title={`Assign Site - ${selectedUser.email}`}
        open={open}
        onClose={onClose}
        width={700}
        top={"15%"}
        fullHeight={true}
      >
        <form>
          <Box className="items-center" my={1}>
            <Box sx={{ width: "300px", mr: 2 }}>
              <FormField
                label="Select Site"
                name="site"
                type="select"
                value={formData.site}
                onChange={onChange}
                options={filteredSiteList}
              />
            </Box>

            <LoadingButton
              loading={loading}
              onClick={onClickSave}
              variant="contained"
              disabled={isEmpty(formData.site)}
            >
              Assign
            </LoadingButton>
          </Box>
        </form>

        <Box mt={2}>
          <DataTable rows={assignedSites} columns={columns} loading={loading} />
        </Box>
        {!isNil(deleteAssignment) && (
          <DeleteConfirmationDialog
            open={true}
            onClose={() => setDeleteAssignment(null)}
            onClickDelete={onClickDelete}
            data="assignment"
          />
        )}
      </Modal>
    </Box>
  );
};

export default AssignSiteUserModal;
