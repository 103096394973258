/* eslint-disable no-undef */
import { Box, Tooltip, Typography } from "@mui/material";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import SiteName from "components/Site/SiteName";
import UnitSiteStatus from "components/Unit/UnitSiteStatus";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { formatDate } from "utils/dayjs";

const containerStyle = {
  minWidth: "100%",
  minHeight: "400px",
  height: "100%",
};

const WeatherSiteInfoWindow = ({ marker }) => {
  return (
    <Box width="400px">
      <Typography variant="h4">Weather Site</Typography>
      <Tooltip title="Navigate to Weather Site">
        <Box className="items-center">
          <Link
            target="_blank"
            to={`https://openweathermap.org/city/${marker.id}`}
            className="text-link"
          >
            <Typography>{marker.id}</Typography>
          </Link>
        </Box>
      </Tooltip>
      <Typography variant="body1">{marker.name}</Typography>
      <Typography variant="body1">
        Irradiance Actual{" "}
        {formatDate(marker.irradiance_actual_earliest_time, "DD/MM/YYYY")}{" "}
        Forecast{" "}
        {formatDate(marker.irradiance_forecast_earliest_time, "DD/MM/YYYY")}
      </Typography>

      <Typography variant="body1">
        Weather Actual{" "}
        {formatDate(marker.weather_actual_earliest_time, "DD/MM/YYYY")} Forecast{" "}
        {formatDate(marker.weather_forecast_earliest_time, "DD/MM/YYYY")}
      </Typography>
    </Box>
  );
};

const SiteInfoWindow = ({ marker }) => {
  return (
    <Box width="300px">
      <Typography variant="h4">Site</Typography>
      <Box className="items-center">
        <SiteName id={marker.id} bold={true} />
      </Box>

      {marker?.units?.map((unit, i) => (
        <UnitSiteStatus key={i} status={unit} />
      ))}
    </Box>
  );
};

const SiteMap = ({ list = [], weatherSiteList = [] }) => {
  const initialCenter = {
    lat: -33.765515,
    lng: 151.272005,
  };

  const [zoom, setZoom] = useState(10);
  const [center, setCenter] = useState(initialCenter);
  const [locatedCenter, setLocatedCenter] = useState(false);
  const [activeMarker, setActiveMarker] = useState(null);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  // eslint-disable-next-line no-unused-vars
  const [map, setMap] = useState(null);

  useEffect(() => {
    if (list.length > 0 && !locatedCenter) {
      const site = list[0];
      setLocatedCenter(true);
      setCenter({
        lat: site?.latitude,
        lng: site.longitude,
      });
      setZoom(10);
    }
  }, [list]);

  const onLoad = useCallback(function callback(map) {
    map.setZoom(zoom);

    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  const redMarkerIcon = isLoaded
    ? {
        url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
        scaledSize: new window.google.maps.Size(44, 40),
      }
    : null;

  const weatherMarkerIcon = isLoaded
    ? {
        url: "http://maps.google.com/mapfiles/ms/icons/purple-dot.png",
        scaledSize: new window.google.maps.Size(44, 40),
      }
    : null;

  return (
    <Box height="100%">
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          options={{ zoomControlOptions: { position: 5 } }}
          center={center}
          zoom={zoom}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          <Box>
            {list.map((marker, i) => (
              <Marker
                key={i}
                {...marker}
                icon={redMarkerIcon}
                title={marker.nmi}
                name={marker.nmi}
                position={{
                  lat: Number(marker.latitude),
                  lng: Number(marker.longitude),
                }}
                onClick={() => setActiveMarker(marker)}
              >
                {activeMarker?.id === marker.id ? (
                  <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                    <SiteInfoWindow marker={marker} />
                  </InfoWindow>
                ) : null}
              </Marker>
            ))}

            {weatherSiteList.map((marker, i) => (
              <Marker
                key={`extraList-marker-${i}`}
                {...marker}
                icon={weatherMarkerIcon}
                title={marker.name}
                name={marker.name}
                position={{
                  lat: Number(marker.lat),
                  lng: Number(marker.lon),
                }}
                onClick={() => setActiveMarker(marker)}
              >
                {activeMarker?.id === marker.id ? (
                  <InfoWindow
                    position={{
                      lat: Number(marker.lat),
                      lng: Number(marker.lon),
                    }}
                    onCloseClick={() => setActiveMarker(null)}
                  >
                    <WeatherSiteInfoWindow marker={marker} />
                  </InfoWindow>
                ) : null}
              </Marker>
            ))}
          </Box>
        </GoogleMap>
      )}
    </Box>
  );
};

export default SiteMap;
