import { API } from "aws-amplify";
import {
  constructOrganisationUrl,
  constructStatusQueryString,
} from "utils/actions";

export const GET_WEATHER_SITE_LIST_REQUEST =
  "@@weatherSite/GET_WEATHER_SITE_LIST_REQUEST";
export const GET_WEATHER_SITE_LIST_SUCCESS =
  "@@weatherSite/GET_WEATHER_SITE_LIST_SUCCESS";
export const GET_WEATHER_SITE_LIST_FAILURE =
  "@@weatherSite/GET_WEATHER_SITE_LIST_FAILURE";

export function getWeatherSiteList(formData = undefined) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_WEATHER_SITE_LIST_REQUEST,
    });
    try {
      const { profile } = getState();
      const { organisation } = profile;

      let url = "/weather_site";
      if (formData) {
        url = constructStatusQueryString(url, {
          ...formData,
          limit: null,
          offset: null,
          view: null,
        });
      } else {
        constructOrganisationUrl(url, organisation);
      }
      const response = await API.get("cmsAPI", url);

      dispatch({
        type: GET_WEATHER_SITE_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_WEATHER_SITE_LIST_FAILURE,
        payload: error,
      });
    }
  };
}
