import { Box, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const UnitSerial = ({ id }) => {
  const [unit, setUnit] = useState({});

  const list = useSelector((state) => state.unit?.list);

  useEffect(() => {
    if (id && list.length > 0) {
      const item = list.find((it) => it.id === id);

      if (item) {
        setUnit(item);
      } else {
        setUnit({});
      }
    } else {
      setUnit({});
    }
  }, [id, list]);

  return (
    <Box className="items-center">
      <Tooltip title="Click to Navigate" placement="top" arrow>
        <Link to={`/units/details/${id}`} className="text-link">
          <Typography variant="body2" fontSize={"13px"} fontWeight={400}>
            {unit?.serial}
          </Typography>
        </Link>
      </Tooltip>
    </Box>
  );
};

export default UnitSerial;
